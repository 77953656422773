.TextDocumentView {
    header {
        border-bottom: solid 1px #000;
        form {
            display: flex;
            flex-direction: column;

            label {
                display: flex;
                flex-direction: column;
            }
        }
    }
}