.UserLogin {
    display: flex;
    flex-direction: column;

    span {
        flex: 1 1 auto;
        display: flex;

        button {
            flex: 1 1 auto;
            
        }
    }
}