.DocumentPicker {
    display: flex;
    flex-direction: column;

    header {
        display: flex;
        flex-direction: column;

        span {
            flex: 1 1 auto;
            display: flex;

            input {
                flex: 1 1 auto;
            }
        }
    }

    .ListView {
        flex: 1 1 auto;
    }
}