.ChatInput {
    display: flex;
    flex-direction: column;
    align-items: flex-end;

    textarea {
        flex: 1 1 auto;
    }

    #Inputs {
        display: flex;
        flex: 1 0 auto;
        width: 100%;
    }

    #AutoResponse {
        flex: 0 1 auto;
    }
}