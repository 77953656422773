.UserList {
    display: flex;
    flex-wrap: wrap;
    flex-direction: column;

    .UserContainer {
        display: flex;

        .UserStatus {
            display: flex;
            align-items: center;
        }
    }
}