.TextEditor {
    display: flex;
    flex-direction: column;

    .MarkDownView {
        flex: 1 1;
        overflow: scroll;
        flex-basis: 0;
    }

    textarea {
        flex: 1 1 auto;
    }
}