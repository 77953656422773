.AIImageView {
    header {
        border-bottom: solid 1px #000;

        form {
            display: flex;
            flex-direction: column;

            label {
                display: flex;
                flex-direction: column;
            }
        }
    }

    .GeneratedImage {
        flex: 1 1;
        overflow-y: scroll;
        flex-basis: 0px;

        img {
            width: 100%;
        }
    }
}