.InteractorView {
    display: flex;
    flex-direction: column;
    height: 100%;

    .ChatView {
        flex: 1 1 0px;
        overflow-y: scroll;
        flex-basis: 0px;
    }

    #InteractorSettings {
        display: flex;
        flex-direction: column;

        label {
            flex: 1 1 auto;
            display: flex;
            flex-direction: column;
        }
    }
}