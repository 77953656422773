#root {
    height: 100%;
    display: grid;
    grid-template: "header header" min-content "docs interactor"  / 1fr 1fr;
}

.Header {
    grid-area: header;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: end;
}

.TextDocumentDisplay {
    grid-area: docs;

    display: flex;
    flex-direction: column;

    .DocumentView {
        flex: 1 1 auto;
        display: flex;

        .TextDocumentView,
        .AIImageView {
            flex: 1 1 auto;

            display: flex;
            flex-direction: column;

            .TextEditor {
                flex: 1 1 auto;
            }
        }
    }
}

.InteractorDisplay {
    grid-area: interactor;
    display: flex;
    flex-direction: column;
    
    .ChatView {
        .MessageView {
            header {
                display: flex;
                justify-content: space-between;
            }
        }
    }
}