.UserList {
    .UserStatus {
        margin-right: 0.5em;

        .StatusIcon {
            display: inline-block;
            margin-right: 0.25em;

            width: 1em;
            height: 1em;

            border-radius: 50%;

            transition: background-color 0.25s;
            background-color: grey;

            &.Online {
                background-color: green;
            }

            &.Offline {
                background-color: red;
            }
        }

        .RemoveUser {
            margin-left: 0.25em;
            transition: color 0.25s;
            font-weight: bold;
            color: grey;

            user-select: none;
            cursor: pointer;

            &:hover {
                color: red;
            }
        }
    }
}

.InteractorDisplay {
    .InteractorView {
        .ChatView {
            .MessageView {
                .Name {
                    font-weight: bold;
                }
            }
        }
    }
}

@media (prefers-color-scheme: light) {
    .InteractorDisplay {
        >header {
            border-bottom: 1px solid #000000;
        }

        .InteractorView {
            .ChatView {
                box-shadow: inset 1px 1px 15px rgba(61, 61, 61, 0.313);

                .MessageView {
                    border-bottom: 1px solid #000000;
                }
            }
        }
    }

    .Header {
        border-bottom: 1px solid #000000;
    }
}

@media (prefers-color-scheme: dark) {
    html {
        background-color: rgb(11, 20, 22);
        color: rgb(242, 244, 245);
    }

    button {
        background-color: rgb(45, 78, 86);
        color: white;

        &:disabled {
            background-color: rgb(29, 49, 54);
            color: #8e8e8e;
        }
    }

    input, select, textarea {
        background-color: rgb(40, 51, 49);
        color: white;
    }

    .InteractorDisplay {
        >header {
            border-bottom: 1px solid #ffffff;
        }

        .InteractorView {
            .ChatView {
                box-shadow: inset 1px 1px 15px rgba(0, 0, 0, 0.313);

                .MessageView {
                    border-bottom: 1px solid #ffffff;
                }
            }
        }
    }

    .Header {
        border-bottom: 1px solid #ffffff;
    }

}